import { Divider, Grid, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Modal from '~/components/common/Modal';
import Tutorial from '~/components/common/Tutorial';
import { TutorialData } from '~/constants/tutorial';
import { ITutorial } from '~/models/common';
import { useAuth } from '~/providers/AuthProvider';
import themes from '~/themes';
import { Form, Formik } from 'formik';
import { FastField, Field } from '~/components/common/Formik';
import { Icon } from '~/components/common/Icon';
import { UpgradePlanButton } from '~/components/shared/UpgradePlan';
import { usePaymentInfo } from '~/providers/PaymentProvider';
import { alertParams, showAlert } from '~/components/common/Alert';
import { queryClient } from '~/lib/react-query';
import { queryKeys } from '~/services/api/queryKeys';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import { useBulkCreateAddressMutation } from '~/services/api/addresss';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { isDesktop } from 'react-device-detect';
import { AssignTask } from '~/components/shared/AssignTask';
import { clearItem, get, LOCAL_STORAGE_KEYS, set } from '~/utils/localStorage';
import { AccordionItem } from './components/AccordionItem';
import { DEFAULT_ADD_TASK_DATA } from '../../constants';
import { AddTaskSchema, AddTaskWithoutPhonePickupSchema } from '../../schema';
import { AddressForm } from './components/AddressForm';
import {
  LiveTrackingQueryKeys,
  useAddTask,
  useGetAllAddressBook,
  useUpdateTask,
  useGetAllAddressCustomer,
} from '../../apis';
import { IncludePickupTask } from './components/IncludePickupTask';
import { DeliveryTimeForm } from './components/DeliveryTimeForm';
import { Task as ITask } from '../../types';
import {
  convertSubmitTaskData,
  convertEditTaskData,
  getAddressBookList,
  isAddressBookEqualData,
} from '../../utils';
// import { AssignDriver } from '../TaskDetail/components/AssignDriver';

type DialogAddTaskProps = {
  open?: boolean;
  task?: ITask;
  onClose: () => void;
  isDuplicate?: boolean;
};

enum Step {
  EnterTaskDetails = 1,
  AssignTaskAdded = 2,
}

const AccordionId = {
  DeliveryTime: 'delivery-time',
  TaskNote: 'task-note',
};

const accordionStyles = {
  container: {
    borderTop: '1px solid #E9E7F6',
  },
  summary: {
    fontSize: 16,
    fontWeight: 500,
    paddingLeft: 0,
  },
  detail: {
    padding: '0px 10px 10px 0px',
  },
};

export const DialogAddTask: React.FC<DialogAddTaskProps> = ({
  open = true,
  task,
  onClose,
  isDuplicate = false,
}) => {
  const isSaveTask = useFeatureIsOn('cf_1080_save_task_form');
  const isOffValidatePhonePickup = useFeatureIsOn(
    'ubp-ignore-validate-phone-in-warehouse-pickup-addressbook',
  );
  const validationSchema = useMemo(
    () =>
      isOffValidatePhonePickup
        ? AddTaskWithoutPhonePickupSchema
        : AddTaskSchema,
    [isOffValidatePhonePickup],
  );

  const formRef = useRef(null);
  const { data: allAddressBook } = useGetAllAddressBook({});
  const { data: allAddressCustomer } = useGetAllAddressCustomer({});
  const location = useLocation();
  const { isStarterPlan } = usePaymentInfo();
  const {
    account,
    countries,
    isFetchingCountry,
    defaultAddress,
    getTooltipOpen,
    updateUserTooltip,
  } = useAuth();
  const [expandedIds, setExpandedIds] = useState<string[]>([]);

  const { mutate: addTaskMutation, isLoading: isAddTaskLoading } = useAddTask({
    onSuccess: (resp) => {
      onClose();
      clearItem(LOCAL_STORAGE_KEYS.SAVE_TASK_FORM);

      if (location.pathname === '/') {
        queryClient.invalidateQueries([LiveTrackingQueryKeys.TasksList]);
        queryClient.invalidateQueries([LiveTrackingQueryKeys.DriverList]);
        queryClient.invalidateQueries([LiveTrackingQueryKeys.DriverRoutes]);
      } else {
        queryClient.invalidateQueries([LiveTrackingQueryKeys.TasksList]);
      }

      queryClient.invalidateQueries([queryKeys.orgManagement.org]);

      const { name, id: taskId } = resp;

      showAlert({
        ...alertParams.successDark,
        title: (
          <Typography
            sx={{
              fontWeight: 'bold',
              a: {
                color: `${themes.color.primaryOrange} !important`,
              },
            }}
          >
            You’ve created delivery task{' '}
            <a
              href={`?task_id=${taskId}`}
              target='_blank'
              rel='noreferrer'
              style={{ color: themes.color.primaryOrange }}
            >
              {name}
            </a>
          </Typography>
        ),
      });
    },
    onError: () => {
      queryClient.invalidateQueries([queryKeys.orgManagement.org]);
    },
  });

  const { mutate: updateTaskMutation, isLoading: isUpdateTaskLoading } =
    useUpdateTask({
      onSuccess: (updatedTask: ITask) => {
        if (location.pathname === '/') {
          queryClient.invalidateQueries([
            LiveTrackingQueryKeys.TaskDetail,
            updatedTask.id,
          ]);
          queryClient.invalidateQueries([LiveTrackingQueryKeys.TasksList]);
        } else {
          queryClient.invalidateQueries([
            LiveTrackingQueryKeys.TaskDetail,
            updatedTask.id,
          ]);
          queryClient.invalidateQueries([LiveTrackingQueryKeys.TasksList]);
        }

        onClose();
        showAlert(alertParams.successDark);
      },
    });

  const { mutate: bulkCreateAddressBookMutation } =
    useBulkCreateAddressMutation();

  const isEditMode = !!task && !isDuplicate;
  const timezone =
    account.timezone === 'auto' ? moment.tz.guess() : account.timezone;
  const expanded = (id: string) => !!expandedIds.find((e) => e === id);

  const handleChangeExpandedIds = (newId: string) => (event, isExpanded) => {
    setExpandedIds((prevIds) =>
      isExpanded
        ? prevIds.concat([newId])
        : prevIds.filter((id) => id !== newId),
    );
  };

  const [step, setStep] = useState<Step>(Step.EnterTaskDetails);

  const onBackPrevStep = useCallback(() => {
    if (step) {
      setStep((s) => s - 1);
    }
  }, []);

  const isDisabledAddressBook = useCallback((params) => {
    const { address, addressBook, isEmailAdded, editMode } = params;
    if (
      (addressBook &&
        isAddressBookEqualData(address, addressBook, isEmailAdded)) ||
      editMode
    ) {
      return true;
    }
    return false;
  }, []);

  useEffect(() => {
    queryClient.refetchQueries([queryKeys.shared.getCountries]);
  }, []);

  const handleSubmit = (values) => {
    if (!isEditMode && step === Step.EnterTaskDetails) {
      setStep((s) => s + 1);
      return;
    }

    const params = convertSubmitTaskData(values);

    if (isEditMode) {
      updateTaskMutation(params);
    } else {
      // handle add address book if save to address book is checked
      const addressBookList = getAddressBookList(values, countries);
      if (addressBookList.length) {
        bulkCreateAddressBookMutation({ data: addressBookList });
      }
      addTaskMutation(params);
    }
  };

  const dialogTitle = useMemo(() => {
    if (isDuplicate) return 'Duplicate Task';
    if (isEditMode) return 'Edit Task';

    const getTutorialData = (): ITutorial => {
      if (step === Step.EnterTaskDetails) {
        return {
          isOpen: false,
          onClose: () =>
            updateUserTooltip({ [TutorialData.EnterTaskDetails.id]: true }),
          ...TutorialData.EnterTaskDetails,
        };
      }
      if (step === Step.AssignTaskAdded) {
        return {
          isOpen: getTooltipOpen(TutorialData.AssignTaskCreate.id),
          onClose: () =>
            updateUserTooltip({ [TutorialData.AssignTaskCreate.id]: true }),
          ...TutorialData.AssignTaskCreate,
        };
      }
      return null;
    };

    const tutorialData = getTutorialData();
    return tutorialData ? (
      <Tutorial {...tutorialData}>Add Task</Tutorial>
    ) : (
      'Add Task'
    );
  }, [step, isEditMode, isDuplicate]);

  const submitButtonTitle = useMemo(() => {
    if (isDuplicate) return 'Next';
    if (isEditMode) {
      return 'Save Changes';
    }
    if (step === Step.EnterTaskDetails) {
      return 'Next';
    }
    return 'Add Task';
  }, [isEditMode, step]);

  const convertTimeToLocalTime = (t) => (t ? moment.utc(t).tz(timezone) : null);

  const initialValues = useMemo(() => {
    const saveTaskForm = get(LOCAL_STORAGE_KEYS.SAVE_TASK_FORM);
    if (isSaveTask && saveTaskForm) {
      saveTaskForm.expected_delivery_after = convertTimeToLocalTime(
        saveTaskForm?.expected_delivery_after,
      );
      saveTaskForm.expected_delivery_before = convertTimeToLocalTime(
        saveTaskForm?.expected_delivery_before,
      );
      saveTaskForm.expected_delivery_date =
        saveTaskForm?.expected_delivery_before
          ? moment
              .utc(saveTaskForm?.expected_delivery_before)
              .tz(timezone)
              .format('MM/DD/YYYY')
          : null;

      return saveTaskForm;
    }
    if (isEditMode) {
      return convertEditTaskData(task, timezone);
    }

    if (isDuplicate) {
      return convertEditTaskData(
        {
          ...task,
          pickup: {
            ...DEFAULT_ADD_TASK_DATA.pickup,
            ...defaultAddress.pickup,
            ...task.pickup,
          },
        },
        timezone,
      );
    }

    return {
      ...DEFAULT_ADD_TASK_DATA,
      pickup: { ...DEFAULT_ADD_TASK_DATA.pickup, ...defaultAddress.pickup },
      delivery: {
        ...DEFAULT_ADD_TASK_DATA.delivery,
        ...defaultAddress.delivery,
      },
    };
  }, [isEditMode, account, defaultAddress, timezone, task, isSaveTask]);

  const addTaskForm = useCallback(
    ({ values, setFieldValue }) => {
      if (isSaveTask && !isEditMode) {
        set(LOCAL_STORAGE_KEYS.SAVE_TASK_FORM, values);
      }
      return (
        <Grid container gap={2}>
          <Grid item xs={12}>
            <IncludePickupTask
              skipPickup={values.skip_pickup}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                if (isEditMode) return;
                setFieldValue('skip_pickup', !e.target.checked);
              }}
            />
          </Grid>
          {!values.skip_pickup && (
            <>
              <Divider sx={{ width: '100%' }} />
              <Grid container rowSpacing={2} columnSpacing={3}>
                <Grid
                  item
                  xs={12}
                  gap={0.5}
                  sx={{ display: 'flex', alignItems: 'center' }}
                >
                  <Icon
                    name='store'
                    baseUrl='map'
                    color={themes.color.primaryOrange}
                    size={12}
                  />
                  <Typography fontWeight={500} fontSize={16}>
                    Pickup Information
                  </Typography>
                </Grid>
                <AddressForm
                  parent='pickup'
                  label={{ name: 'Sender’s Name' }}
                  countries={countries || []}
                  loading={isFetchingCountry}
                  allAddressBook={allAddressBook}
                  allAddressCustomer={allAddressCustomer}
                />
                <Grid item xs={12}>
                  <Field.CheckBox
                    disabled={isDisabledAddressBook({
                      address: values?.pickup,
                      addressBook: values.pickup.address_book,
                      isEmailAdded: false,
                      editMode: isEditMode,
                    })}
                    name='pickup.save_to_address_book'
                    label='Save this contact in address book'
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography fontWeight={500} fontSize={14}>
                    Proofs of Pickup
                  </Typography>
                  <FastField.CheckBox
                    name='require_pickup_proofs'
                    label='Require drivers to scan barcode or capture an image of the package upon pickup.'
                  />
                </Grid>
              </Grid>
            </>
          )}

          <Divider sx={{ width: '100%' }} />

          <Grid container rowSpacing={2} columnSpacing={3}>
            <Grid
              item
              xs={12}
              gap={0.5}
              sx={{ display: 'flex', alignItems: 'center' }}
            >
              <Icon
                name='drop-off'
                baseUrl='map'
                color={themes.color.black}
                size={12}
              />
              <Typography fontWeight={500} fontSize={16}>
                Dropoff Information
              </Typography>
            </Grid>
            <AddressForm
              parent='delivery'
              label={{ name: 'Recipient’s Name' }}
              countries={countries || []}
              loading={isFetchingCountry}
              allAddressBook={allAddressBook}
              allAddressCustomer={allAddressCustomer}
            />
            <Grid item xs={12}>
              <Field.CheckBox
                disabled={isDisabledAddressBook({
                  address: values?.delivery,
                  addressBook: values.delivery.address_book,
                  isEmailAdded: true,
                  editMode: isEditMode,
                })}
                name='delivery.save_to_address_book'
                label='Save this contact in address book'
                value={values.delivery.save_to_address_book}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography fontWeight={500} fontSize={14}>
                Proofs of Drop-off
              </Typography>
              <FastField.CheckBox
                name='require_delivery_capture'
                label='Require drivers to capture an image of the package upon dropoff.'
              />
              <FastField.CheckBox
                name='require_delivery_signature'
                label='Require drivers to collect the customer’s signature upon dropoff.'
              />
            </Grid>
          </Grid>
          <Stack width='100%'>
            <AccordionItem
              id={AccordionId.DeliveryTime}
              summaryContent={
                <Stack direction='row' alignItems='center'>
                  <span
                    style={{
                      ...(isStarterPlan && {
                        opacity: 0.5,
                      }),
                    }}
                  >
                    Delivery Time Window (Optional)
                  </span>
                  <UpgradePlanButton
                    isShowButton={isStarterPlan}
                    sxProps={{ ml: 1, opacity: 1 }}
                  />
                </Stack>
              }
              expanded={(id) => (isStarterPlan ? false : expanded(id))}
              isDisabledExpandIcon={isStarterPlan}
              styles={accordionStyles}
              handleChangeExpandedIds={handleChangeExpandedIds}
            >
              <DeliveryTimeForm />
            </AccordionItem>
            <AccordionItem
              id={AccordionId.TaskNote}
              summaryContent='Task Note (Optional)'
              expanded={expanded}
              styles={accordionStyles}
              handleChangeExpandedIds={handleChangeExpandedIds}
            >
              <FastField.TextInput
                multiline
                rows={4}
                name='note'
                placeholder='add note here ...'
              />
            </AccordionItem>
          </Stack>
        </Grid>
      );
    },
    [
      allAddressBook,
      expandedIds,
      isStarterPlan,
      isEditMode,
      isFetchingCountry,
      isSaveTask,
    ],
  );

  const assignTaskForm = useCallback(
    ({ values, setFieldValue }) => (
      <Stack
        spacing={1}
        sx={
          {
            // '& > div': {
            //   padding: 0,
            // },
          }
        }
      >
        <AssignTask
          hubId={values?.pickup_hub_id}
          pickup={values.skip_pickup ? {} : values.pickup}
          delivery={values.delivery}
          onChangeAssigner={({ id, type }) => {
            setFieldValue('executor_id', id);
            setFieldValue('executor_type', type);
          }}
        />
      </Stack>
    ),
    [],
  );

  const onCloseModal = () => {
    clearItem(LOCAL_STORAGE_KEYS.SAVE_TASK_FORM);
    onClose();
  };

  return (
    <Modal
      title={dialogTitle}
      open={open}
      onClose={onCloseModal}
      disableCloseOutside
      closeButtonStyles={{
        background: 'transparent',
        iconSize: 16,
        sx: { top: '20px !important' },
      }}
      PaperProps={{
        sx: {
          minHeight: 600,
          minWidth: 690,
          ...(!isDesktop && {
            width: '100%',
            minWidth: 'unset',
            minHeight: 'unset',
          }),
        },
      }}
      customActions={[
        {
          title:
            step === Step.EnterTaskDetails || isEditMode ? 'Cancel' : 'Back',
          onClick: () => {
            if (step === Step.EnterTaskDetails) {
              clearItem(LOCAL_STORAGE_KEYS.SAVE_TASK_FORM);
              onClose();
            }
            return onBackPrevStep();
          },
          buttonType: 'default',
        },
        {
          title: submitButtonTitle,
          buttonType: 'primary-dark',
          onClick: async () => {
            await formRef?.current?.setFieldTouched(
              'expected_delivery_before',
              true,
            );
            const errors = await formRef?.current?.validateForm();
            if (Object.keys(errors).length === 0)
              formRef?.current?.handleSubmit();
          },
          loading: isAddTaskLoading || isUpdateTaskLoading,
        },
      ]}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        enableReinitialize
        validationSchema={validationSchema}
        innerRef={formRef}
      >
        {({ values, setFieldValue }) => (
          <Form
            id='add-task'
            onKeyDown={(e) => {
              if (e.code === 'Enter') {
                e.preventDefault();
              }
            }}
          >
            {step === Step.EnterTaskDetails
              ? addTaskForm({ values, setFieldValue })
              : assignTaskForm({ values, setFieldValue })}
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
