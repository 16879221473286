import { Box, Paper, Stack, Typography } from '@mui/material';
import { styled } from '~/themes';
import Grid from '@mui/material/Unstable_Grid2';
import { LinkButton } from '~/components/common/Button';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { usePaymentInfo } from '~/providers/PaymentProvider';
import { IBillingFrequency, IPlanTier } from '~/models/plan';
import moment from 'moment';
import { formatPrice } from '~/utils/formatter';
import { Icon } from '~/components/common/Icon';
import { alertParams, showAlert } from '~/components/common/Alert';
import { queryKeys } from '~/services/api/queryKeys';
import { queryClient } from '~/lib/react-query';
import { DialogUpdatePayment } from './DialogUpdatePayment';
import { BillingHistory } from './BillingHistory';

type BillingProps = {};

export const StyledBillingContainer = styled(Box)(({ theme }) => ({
  maxWidth: 1000,
  minWidth: 1000,
  flexGrow: 1,
  [theme.breakpoints.down('ss')]: {
    minWidth: 0,
  },
}));

export const StyledBillingItem = styled(Paper)(({ theme }) => ({
  boxShadow: '0px 2px 10px #EDF1FB',
  borderRadius: 10,
  background: theme.color.white,
  padding: theme.spacing(3),
}));

export const StyledBillingValue = styled(Typography)(({ theme }) => ({
  fontSize: 16,
  fontWeight: 500,
  marginTop: theme.spacing(1),
}));

export const StyledBillingTitle = styled(Typography)(() => ({
  fontSize: 16,
  fontWeight: 500,
}));

export const StyledBillingValueWrapper = styled(Box)(({ theme }) => ({
  color: theme.color.black,
}));

export const Billing: React.FC<BillingProps> = () => {
  const navigate = useNavigate();
  const [openUpdatePayment, setOpenUpdatePayment] = useState(false);
  const { org, currentPlan, isBundlePlan } = usePaymentInfo();
  const cardInfo = org?.card || {};

  return (
    <StyledBillingContainer>
      <Grid container spacing={2}>
        <Grid xs={12} md={6}>
          <StyledBillingItem>
            <Stack direction='row' justifyContent='space-between'>
              <StyledBillingTitle>My Plan</StyledBillingTitle>
              {!isBundlePlan && (
                <LinkButton onClick={() => navigate('../setting/plans')}>
                  Update
                </LinkButton>
              )}
            </Stack>
            <Stack direction='row' spacing={3} mt={2}>
              <StyledBillingValueWrapper>
                <Typography>
                  {isBundlePlan ? 'Dispensary' : currentPlan?.metadata?.title}{' '}
                  Plan
                </Typography>
                <StyledBillingValue>
                  {currentPlan?.metadata && !isBundlePlan
                    ? formatPrice(
                        currentPlan.frequency === IBillingFrequency.Month
                          ? currentPlan?.metadata?.price_num
                          : (currentPlan?.metadata?.price_num ?? 0) * 12,
                      )
                    : '-'}
                </StyledBillingValue>
              </StyledBillingValueWrapper>

              <StyledBillingValueWrapper>
                {currentPlan.tier === IPlanTier.Free || isBundlePlan ? (
                  <Typography>-</Typography>
                ) : (
                  <Typography>
                    {currentPlan.frequency === IBillingFrequency.Month
                      ? 'Billed monthly'
                      : 'Billed annually'}
                  </Typography>
                )}

                <StyledBillingValue>
                  {org?.current_period_end
                    ? `Next bill ${moment
                        .unix(org.current_period_end)
                        .format('MM/DD/YYYY')}`
                    : '-'}
                </StyledBillingValue>
              </StyledBillingValueWrapper>
            </Stack>
          </StyledBillingItem>
        </Grid>

        <Grid xs={12} md={6}>
          <StyledBillingItem>
            <Stack direction='row' justifyContent='space-between'>
              <StyledBillingTitle>
                Payment Method{' '}
                {org?.grace_period && (
                  <Icon
                    name='exclamation'
                    useBackgroundImg
                    size={14}
                    style={{ marginBottom: -1 }}
                  />
                )}
              </StyledBillingTitle>
              {!isBundlePlan && (
                <LinkButton onClick={() => setOpenUpdatePayment(true)}>
                  Update
                </LinkButton>
              )}
            </Stack>
            <Stack direction='row' spacing={3} mt={2}>
              <StyledBillingValueWrapper>
                <Typography>Credit Card</Typography>
                <StyledBillingValue>
                  **** **** **** {cardInfo.last4}
                </StyledBillingValue>
              </StyledBillingValueWrapper>

              <StyledBillingValueWrapper>
                <Box minHeight={20} />
                <StyledBillingValue>
                  Expires{' '}
                  {cardInfo?.exp_month?.toString().length === 1
                    ? `0${cardInfo?.exp_month?.toString()}`
                    : cardInfo?.exp_month?.toString()}
                  /{cardInfo?.exp_year?.toString().slice(2, 4)}
                </StyledBillingValue>
              </StyledBillingValueWrapper>
            </Stack>
          </StyledBillingItem>
        </Grid>

        <Grid xs={12}>
          <StyledBillingItem>
            <StyledBillingTitle>Billing History</StyledBillingTitle>
            <BillingHistory />
          </StyledBillingItem>
        </Grid>
      </Grid>
      <DialogUpdatePayment
        open={openUpdatePayment}
        onClose={() => setOpenUpdatePayment(false)}
        onUpdatePaymentSuccess={() => {
          setOpenUpdatePayment(false);
          queryClient.invalidateQueries([queryKeys.orgManagement.org]);
          showAlert({
            ...alertParams.success,
            title: 'Update Successfully',
          });
        }}
      />
    </StyledBillingContainer>
  );
};
